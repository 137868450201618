import { ErrorHandler, Injectable } from "@angular/core";
declare var newrelic: any;

@Injectable()
export class AppGlobalErrorhandler implements ErrorHandler {
  handleError(error) {
    if (error && error['name'] && error['code']
      && error['name'] === 'FirebaseError' && error['code'] === 'permission-denied') {
        localStorage.setItem('isloggedinWithFirebase', 'false');
    }
    console.error(error);
    newrelic.noticeError(error);
  }
}
