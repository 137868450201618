export const environment = {
  production: true,
  ENV_PREFIX: 'prod',
  apiDomain: 'https://api.contapsocial.com/',
  apiUrl: 'https://api.contapsocial.com/api',
  microservicesApiUrl: 'https://prod-apigateway.hiosocial.com',
  cloudApiUrl: 'https://us-central1-durable-verve-138719.cloudfunctions.net',
  eventbriteURL:
    'https://www.eventbrite.com/oauth/authorize?response_type=token&client_id=FWBXKZS2G3TNLNXFDM',
  agreementURL: 'https://na3.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=ae214533-c459-4dd9-9796-7648a579df1c&env=na3&acct=8616a82d-da67-4dcd-a1eb-de4887b1d762&v=2',
  stripeKey: 'pk_live_PDgB99nCHyaIsgWQyMa4XBDP000xXDf1KS',
  googleTimezoneKey: 'AIzaSyCDYGZ5Wdwr4FpHjepubTbvwYvgXM9Qh0M',
  highlightPackage: 25,
  mapBoxKey: 'pk.eyJ1IjoianRjcmFwYXJvIiwiYSI6ImNqcDhwaHVhazAyNzMza3BkYXB1czVlenUifQ.-SDUBSJeqR77lC69UDXucw',
  sendBirdAppId: "E2BC0F39-CABF-4EC0-81C5-ECC8371D9DF1",
  pluginUrl: "https://plugin-helper.hiosocial.com/plugin_loader.js",
  firebaseConfig: {
    apiKey: "AIzaSyD33WF0yREXwsPxgETFqbF8rEYqNUM812w",
    authDomain: "durable-verve-138719.firebaseapp.com",
    databaseURL: "https://durable-verve-138719.firebaseio.com",
    projectId: "durable-verve-138719",
    storageBucket: "durable-verve-138719.appspot.com",
    messagingSenderId: "1093447573618",
    appId: "1:1093447573618:web:c85b5026a84d21a47c62eb",
    measurementId: "G-01SF716XGC"
  },
  firebaseDefaultPassword: '123456',
  PLUGIN_INTEGRATION_TOKEN_COLLECION: 'plugin_integration_token',
  ANALYTICS_OVERVIEW_COLLECION: 'AnalyticsDailyOverview',
  ANALYTICS_DEMOGRAPHIC_COLLECION: 'AnalyticsDemographic',
  ANALYTICS_CHANNEL_COLLECION: 'AnalyticsChannel',
  VIP_EVENT_COLLECTION: 'prod_Event',
  VIP_TABLE_COLLECTION: 'VIP_Table',
  VIP_LOUNGE_MEMBERS_COLLECTION: 'members',
  LOUNGE_COLLECTION: 'Lounge_Members',
  AgoraAppId : 'abe4296ad66f41be837cec1575cf6699',
  CONSUMER_KEY: '-LHIHYbca17ogALo4IofXA==',
  CONSUMER_SECRET: 'fNynlbea_J95YN52tQnk2IvCxJlc4RJA_YdDQXIW85w=',
  CONFERENCE_CHANNEL_NAME: 'prod-event-',
  VIRTUAL_MODERATED_COLLECTION: 'prod_virtual_moderated',
  VIRTUAL_MODERATED_ACTIVE_MEMBERS_COLLECTION: 'active_members',
  VIRTUAL_MODERATED_BREAKOUT_ROOMS_COLLECTION: 'breakout_rooms',
  BREAKOUT_ROOM_MEMBERS_COLLECTION: 'room_members',
  VIRTUAL_SPEED_COLLECTION: 'prod_virtual_speed',
  VIRTUAL_SPEED_MEMBERS_COLLECTION: 'speedMembers',
  VIRTUAL_SPEED_TABLE_COLLECTION: 'VIP_Table',
  VIRTUAL_SPEED_TABLE_MEMBERS_COLLECTION: 'member',
  host: "https://myevents.hiosocial.com/",
  FIREBASE_SW: 'firebase-messaging-sw.js',
  captchaSiteKey: '6LetrDUbAAAAAHbd8oXGVy-N_yRXo_qrSZf49ch5',
  product: 'events'
};
