import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AngularFireAuth } from '@angular/fire/auth';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { SharedService } from '../shared/services/shared.service';
import { Constants } from '@shared/helperclasses/constatnts';
import { AuthGuard } from '@shared/guard/guard';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private authState: Observable<firebase.User>;
  private userData: any;
  private userToken: any;
  private firebaseAuthFailedMsg = `We had some technical issues, please logout from Hio and login again or contact support team.`;
  private firebaseAuthErrTitle = 'Authentication error';
  constructor(
    private http: HttpClient,
    public angularFireAuth: AngularFireAuth,
    private notification: NzNotificationService,
    private sharedService: SharedService,
    private authGuard: AuthGuard
  ) {
    this.authState = this.angularFireAuth.authState;
  }

  onLogin(data: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/account/user/send_an_otp`, data);
  }

  onOtpSubmit(data: any): Observable<any> {
    const api = data.action === 'login' ? '/account/user/verify_an_otp' : '/account/user/login_v2';
    const obj: any = {
      country_code: data.code,
      mobile_number: data.num,
      otp: data.otp
    };

    if (data.action === 'signup') {
      delete obj.mobile_number;
      obj.phone_number = data.code + data.num;
      obj.email = data.email;
    } else {
      delete data.email;
    }

    delete data.action;

    return this.http.post(`${environment.apiUrl + api}`, obj);
  }

  onSignUp(data: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/account/user/signup_v3`, data);
  }

  loginByAdmin(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/account/user/me`);
  }

  getUserById(id): Observable<any> {
    return this.http.get(`${environment.apiUrl}/account/user/get_user_details_for_jitsi_call/${id}/`);
  }

  private resetFirebaseUserPassword(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/account/user/reset_firebase_user_password`);
  }

  /* Firebase Sign in */
  private firebaseSignIn() {
    const firebaseToken = localStorage.getItem('firebaseToken');
    return this.angularFireAuth
      .signInWithCustomToken(firebaseToken);
  }

  authStateChanged() {
    this.angularFireAuth.onAuthStateChanged(user => {
      if (user && this.authState !== null) {
        localStorage.setItem('isloggedinWithFirebase', 'true');
        console.log('Firebase Successfully authenticated');
        this.sharedService.firebaseLoginStatus.next(true);
      } else {
        this.sharedService.firebaseLoginStatus.next(false);
        console.log('AUTHSTATE USER EMPTY', user);
        try {
          this.userData = JSON.parse(localStorage.getItem('userInfo'));
          this.userToken = localStorage.getItem('token');
          if (this.userData && this.userToken) {
            this.processFirebaseSignin();
          } else {
            localStorage.setItem('isloggedinWithFirebase', 'false');
            if (this.userData && this.userToken) {
              this.firebaseErrNotification();
            }
          }
        } catch (error) {
          localStorage.setItem('isloggedinWithFirebase', 'false');
          this.sharedService.firebaseLoginStatus.next(false);
          console.log('Firebase auth state error');
          if (this.userData && this.userToken) {
            this.firebaseErrNotification();
          }
        }
      }
    },
      err => {
        if (err) {
          console.log(`${err.code} ${err.message}`, 'Please try again');
        } else {
          console.log(`${err}`, 'Please try again');
        }
      });
  }

  isFirebaseUserAuthenticated(): boolean {
    try {
      this.userData = JSON.parse(localStorage.getItem('userInfo'));
      this.userToken = localStorage.getItem('token');
      if (
        (this.authState === null || this.angularFireAuth.currentUser == null) &&
        this.userData && this.userToken
      ) {
        localStorage.setItem('isloggedinWithFirebase', 'false');
        this.processFirebaseSignin();
      } else {
        localStorage.setItem('isloggedinWithFirebase', 'true');
      }
    } catch (error) {
      localStorage.setItem('isloggedinWithFirebase', 'false');
      console.log('isFirebaseUserAuthenticated error');
    }
    return this.authState !== null && this.angularFireAuth.currentUser !== null;
  }

  /*Firebase Sign out */
  firebaseSignOut() {
    localStorage.setItem('isloggedinWithFirebase', 'false');
    localStorage.setItem('email', '');
    const that = this;
    that.sharedService.clearLocalStorage();
    setTimeout(() => {
      this.sharedService.firebaseLoginStatus.next(false);
      that.angularFireAuth
        .signOut(() => {
          console.log('Firebase Sign out success')
        }).catch(err => {
          console.log(err)
        });
    }, 4000);
  }

  logOut(device_id) {
    return this.http.get(`${environment.apiUrl}/account/user/logout/?device_id=${device_id}`);
  }
  // Firebase login
  processFirebaseSignin() {
    try {
      console.log('Processing for signed in!');
      this.sharedService.firebaseLoginStatus.next(false);
      // firebase sign in
      this.userData = JSON.parse(localStorage.getItem('userInfo'));
      this.userToken = localStorage.getItem('token');
      if (this.userData) {
        const that = this;
        this.firebaseSignIn()
          .then(res => {
            localStorage.setItem('isloggedinWithFirebase', 'true');
            console.log('Successfully signed in!');
          })
          .catch(err => {
            console.log('err : ', err);
            localStorage.setItem('isloggedinWithFirebase', 'false');
            console.log('Firebase sign in, calling reset password');
            that.resetFirebaseUserPassword().toPromise()
              .then(resetPassRes => {
                if (resetPassRes.user) {
                  localStorage.setItem('userInfo', JSON.stringify(resetPassRes.user));
                  localStorage.setItem('firebaseToken', resetPassRes.firebase_token);
                  that.firebaseSignIn()
                    .then(res => {
                      localStorage.setItem('isloggedinWithFirebase', 'true');
                      console.log('Successfully signed in!');
                    })
                    .catch(err => {
                      localStorage.setItem('isloggedinWithFirebase', 'false');
                      this.firebaseErrNotification();
                    });
                } else {
                  localStorage.setItem('isloggedinWithFirebase', 'false');
                  this.firebaseErrNotification();
                }
              }).catch(err => {
                localStorage.setItem('isloggedinWithFirebase', 'false');
                console.log('Firebase sign in failed');
                this.firebaseErrNotification();
              });
          });
      }
    } catch (error) {
      localStorage.setItem('isloggedinWithFirebase', 'false');
      console.log('Firebase error on login');
      this.firebaseErrNotification();
    }
  }

  public firebaseErrNotification() {
    this.notification.create(
      'error',
      this.firebaseAuthErrTitle,
      this.firebaseAuthFailedMsg,
      { nzDuration: 0 }
    );
  }

  logOutUser() {
    this.authGuard.requestedUrl = '';
    // get FCM Push Notification Device Id
    const deviceId = localStorage.getItem(Constants.FCM_DEVICE_ID_KEY);
    if (deviceId) {
      // Logout User from web
      this.logOut(deviceId).subscribe(res => {
        // Sign out from firebase
        this.firebaseSignOut();
        this.sharedService.clearLocalAndRedirectToIndex();
      }, err => {
        // Sign out from firebase
        this.firebaseSignOut();
        this.sharedService.clearLocalAndRedirectToIndex();
      });
    } else {
      // Clear all cache
      // Sign out from firebase
      this.firebaseSignOut();
      this.sharedService.clearLocalAndRedirectToIndex();
    }
  }
}
