import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { SharedService } from '../services/shared.service';
import { MessagingService } from '../services/messaging.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  constructor(private router: Router) { }

  /**
   * This guard is for public routes.
   * This means when a logged in user try to navigate to these routes,
   * it will redirected user to dashboard.
   */
  canActivate(): boolean {
    const token = localStorage.getItem('token');
    const userType = localStorage.getItem('userType');
    if (token) {
      switch (userType) {
        case 'organizer':
          this.router.navigateByUrl('/main/dashboard');
          break;
        case 'attendee':
          this.router.navigateByUrl('/attendee');
          break;
        default:
          this.router.navigateByUrl('/attendee');
      }
      return false;
    } else {
      return true;
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  isProductHovr = environment.product === 'hovr' ? true : false;
  requestedUrl: string;

  constructor(
    private sharedService: SharedService,
    private messagingService: MessagingService
  ) { }

  /**
   * This guard is for private/restricted routes.
   * This means when a logged out user try to navigate to these routes,
   * it will redirect user to login.
   */
  canActivate(route: ActivatedRouteSnapshot, snapshot: RouterStateSnapshot) {

    this.requestedUrl = snapshot.url;
    if (this.requestedUrl.includes('attendee')) {
      localStorage.setItem('userType', 'attendee');
    } else if (this.requestedUrl.includes('main')) {
      localStorage.setItem('userType', 'organizer');
    }

    if (localStorage.getItem('token') && localStorage.getItem('userType')) {
      if (!this.isProductHovr) {
        // Firebase Messaging Service : Required on for Event platform
        if (!localStorage.getItem('FCM_NOTIFICATION_TOKEN') && !localStorage.getItem('FCM_DEVICE_ID')) {
          this.messagingService.requestPermission();
        }
      }

      return true;
    } else {
      this.sharedService.clearLocalAndRedirectToIndex();
      return false;
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  userInfo;

  constructor() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
  }

  /**
   * This guard is for admin routes.
   * The user with admin role can access routes
   */
  canActivate() {
    if (this.userInfo && (this.userInfo['is_superuser'] || this.userInfo['is_staff'])) {
      return true;
    } else {
      return false;
    }
  }
}
