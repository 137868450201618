import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { AuthGuard, LoginGuard } from './shared/guard/guard';
import { SiteAdminPortalGuard } from './shared/guard/sites.guard';
import { LoginByAdminComponent } from './login-by-admin/login-by-admin.component';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'main',
    loadChildren: () => import('./main/main.module').then(m => m.MainModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'attendee',
    loadChildren: () => import('./attendee/attendee.module').then(m => m.AttendeeModule),
    canActivate: [AuthGuard, SiteAdminPortalGuard]
  },
  {
    path: 'login_by_admin',
    component: LoginByAdminComponent
  },
  {
    path: 'connection/user/:token',
    loadChildren: () => import('./public-pages/connection-user/connection-user.module').then(m => m.ConnectionUserModule)
  },
  {
    path: '**',
    redirectTo: 'auth'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      // preloadingStrategy: PreloadAllModules,
      useHash: false,
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'legacy'
    })
  ],
  exports: [
    RouterModule
  ]
})

export class AppRoutingModule {
}
