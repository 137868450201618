export class HelperFunctions {
  /**
 * generate groups of 4 random characters
 * @example getUniqueId(1) : 607f
 * @example getUniqueId(2) : 95ca-361a-f8a1-1e73
 */
  getUniqueId(parts: number): string {
    const stringArr = [];
    for (let i = 0; i < parts; i++) {
      // tslint:disable-next-line:no-bitwise
      const S4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      stringArr.push(S4);
    }
    return stringArr.join('-');
  }

  CreateUniqueID() {
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
  }
  randomIntFromInterval(min, max) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  getFirebaseUserWithRole(member, role): any {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (userInfo) {
      const fireBaseUID = userInfo['firebase_uid'];
      let userRole = { [fireBaseUID]: role };
      member['roles'] = userRole;
    }
    return member;
  }

  // Deep clone array objects
  deepClone(obj: any) {
    let output;
    let value;
    let key;
    output = obj === null ? null : Array.isArray(obj) ? [] : {};
    for (key in obj) {
      if (obj.hasOwnProperty(key)) {
        value = obj[key];
        output[key] = typeof value === 'object' ? this.deepClone(value) : value;
      }
    }
    return output;
  }
}
