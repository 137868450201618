import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from './authentication/auth.service';
import { MessagingService } from './shared/services/messaging.service';
declare const window: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  constructor(
    router: Router,
    private authService: AuthService,
  ) {
    this.authService.authStateChanged();
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        window.Appcues.identify(60192);
        window.Appcues.page();
      }
    });
  }
}
