import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as firebase from 'firebase';
import 'rxjs/add/operator/take';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../helperclasses/constatnts';
import { HelperFunctions } from 'src/app/shared/helperclasses/helper-functions';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  messaging: any;
  notificationMessage = new BehaviorSubject(null);
  constructor(
    private http: HttpClient,
    private angularFireMessaging: AngularFireMessaging,
  ) {
    if (firebase.messaging.isSupported()) {
      this.messaging = firebase.messaging();
      // navigator.serviceWorker.getRegistration().then(swr => firebase.messaging().useServiceWorker(swr));
    }
  }

  requestPermission() {
    if (this.messaging && !localStorage.getItem(Constants.FCM_NOTIFICATION_TOKEN_KEY)) {
      this.messaging.requestPermission()
        .then(() => {
          console.log('Notification permission granted.');
          // get the token in the form of promise
          return this.messaging.getToken();
        })
        .then(token => {
          // print the token
          console.log('token successfull');
          this.registerFCMDevice(token);
          // this.tokenRefresh();
         // this.receiveMessage();
        })
        .catch((err) => {
          console.log('Unable to get permission to notify.', err);
        });
    }
  }

  tokenRefresh() {

    this.angularFireMessaging.tokenChanges.subscribe(
      (token) => {
        console.log('new token received.');
        this.registerFCMDevice(token);
      },
      (err) => {
        console.error('Unable to get token to notify.', err);
      });
  }

  registerFCMDevice(token) {
    // console.log('token: ' + token);
    if (!localStorage.getItem(Constants.FCM_NOTIFICATION_TOKEN_KEY) && !localStorage.getItem(Constants.FCM_DEVICE_ID_KEY)) {
      localStorage.setItem(Constants.FCM_NOTIFICATION_TOKEN_KEY, token);
      const deviceId = new HelperFunctions().CreateUniqueID(); // ⇨ Example Of UUID '9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d'
      localStorage.setItem(Constants.FCM_DEVICE_ID_KEY, deviceId);
      // console.log(token);
      if (token && deviceId) {
        const regObj = {
          'device_fcm_token': token,
          'device_type': 'web',
          'device_id': deviceId
        };
        this.http.post(`${environment.apiUrl}/account/fcmdevice/fcmdevice_details_update/`, regObj)
          .subscribe(res => {
            console.log('Firebase messaging service device Id registered successfully');
          }, err => {
            console.log('messaging service register FCM Device');
            localStorage.setItem(Constants.FCM_DEVICE_ID_KEY, '');
            console.log(err);
          });
      }
    }
  }

  // receiveMessage() {
  //   this.angularFireMessaging.messages.subscribe(
  //     (payload) => {
  //       console.log('new message received.');
  //       this.notificationMessage.next(payload);
  //     });
  // }
}
