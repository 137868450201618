import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { SharedService } from '../services/shared.service';
import { Messages } from '../services/messages';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private message: NzMessageService,
    private modalService: NzModalService,
    private sharedService: SharedService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('token');
    request = request.clone({
      setHeaders: {
        Accept: 'application/json'
      }
    });
    if (token && !request.url.match(/maps.googleapis.com/g)) {
      request = request.clone({
        setHeaders: {
          Authorization: token,
          Accept: 'application/json'
        }
      });
    }
    return next.handle(request).pipe(catchError(error => this.errorHandler(error)));
  }

  private errorHandler(response: HttpErrorResponse): Observable<HttpEvent<any>> {
    if (response.status === 401 || response.status === 403) {
      this.modalService.closeAll();
      this.message.create('error', Messages.errors.sessionExpired);
      this.sharedService.clearLocalAndRedirectToIndex();
    } else if (response.status === 400) {
      const error = (response && response.error && response.error.error || response.error.message) || Messages.errors.badRequest;
      this.message.create('error', error);
    } else if (response.status === 422) {
      this.message.create('error', Messages.errors.validationErr);
    } else if (response.status >= 500) {
      this.message.create('error', Messages.errors.serverErr);
    }
    throw response;
  }
}
