import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MainService {

  constructor(
    private http: HttpClient
  ) { }

  getDashboardEvents(offset: number, limit: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}/event/event/get_my_events_v2/?limit=${limit}&offset=${offset}&platform=web&exclude_event_type=past`);
  }

  getDashboardGraphData(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/event/event/get_dashboard_stats`);
  }

  getCommunicateEvents(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/event/event/get_event_email_communication_details/`);
  }

  updateUserData(userID: string, data: any): Observable<any> {
    return this.http.patch(`${environment.apiUrl}/account/user/${userID}`, data);
  }

  getPastEvent(limit, offset): Observable<any> {
    return this.http.get(`${environment.apiUrl}/event/event/get_past_events_v2/?limit=${limit}&offset=${offset}&platform=web`);
  }

  getEventAnalytics(id: any): Observable<any> {
    let url = `${environment.apiUrl}/event/event/my_event_analytics`;
    if (id !== 'all') { url = url + '?events=' + id; }
    return this.http.get(url);
  }

  getRevenewChartData() {
    return this.http.get(`${environment.apiUrl}/eventpartner/partner/get_premium_payout_data`);
  }

  getBillingData() {
    return this.http.get(`${environment.apiUrl}/eventpartner/partner/get_my_partner_profile`);
  }

  updateBilling(data) {
    return this.http.post(`${environment.apiUrl}/eventpartner/partner/update_my_partner_profile`, data);
  }

  createStripeCard(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/eventpartner/partner/create_stripe_card/`, data);
  }

  updateStripeCard(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/eventpartner/partner/update_stripe_card/`, data);
  }

  getCard() {
    return this.http.get(`${environment.apiUrl}/eventpartner/partner/get_stripe_card_list/`);
  }

  deleteStripeCard(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/eventpartner/partner/delete_stripe_card/`, data);
  }

  getUserMe(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/account/user/me/`);
  }

  getStripeTransaction(limit, offset) {
    return this.http.get(`${environment.apiUrl}/eventpartner/stripetransaction/?limit=${limit}&offset=${offset}`);
  }

  getInterests(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/account/interest/?limit=0&offset=0`);
  }

  getIndustries(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/account/industry/?limit=0&offset=0`);
  }

  getHelpful(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/account/helpful/?limit=0&offset=0`);
  }

  getLookingfor(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/account/lookingfor/?limit=0&offset=0`);
  }

  createNewInterest(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/account/interest/`, data);
  }

  createNewHelpful(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/account/helpful/`, data);
  }
  
  createNewLookingfor(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/account/lookingfor/`, data);
  }

  addUpdateUserSocial(data: any): Observable<any> {
    return this.http.patch(`${environment.apiUrl}/account/usersocial/`, data);
  }

  getSocials(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/social/social?limit=0&offset=0`);
  }

  getUserConnections(): Observable<any> {
    return this.http.get(`${environment.microservicesApiUrl}/account/friend`);
  }

  getChatChannelList(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/account/userchatchannelinfo/get_chat_channel_list/`);
  }

  getUserChatChannelInfo(id): Observable<any> {
    return this.http.get(`${environment.apiUrl}/account/userchatchannelinfo/get_chat_channel_info/?to_user_id=${id}`);
  }

  getUserData(userID): Observable<any> {
    return this.http.get(`${environment.apiUrl}/account/user/${userID}`);
  }

  giveCompliment(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/account/usercompliment/`, data);
  }

  removeCompliment(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/account/usercompliment/delete_user_compliment/`, data);
  }

  createNewNote(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/account/note/`, data);
  }

  editNote(data, note_id): Observable<any> {
    return this.http.patch(`${environment.apiUrl}/account/note/${note_id}`, data);
  }

  deleteNote(note_id): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/account/note/${note_id}`);
  }

  blockUser(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/reportabuse/block/block_user/`, data);
  }

  reportUser(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/reportabuse/reportabuse/`, data);
  }

  shareProfile(data): Observable<any> {
    return this.http.patch(`${environment.apiUrl}/account/share/`, data);
  }

  getSharedSocials(toUser, fromUser): Observable<any> {
    return this.http.get(`${environment.apiUrl}/account/share?limit=0&offset=0&to_user__id=${toUser}&from_user__id=${fromUser}`);
  }

  declineRequest(id): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/account/requestshare/${id}`);
  }

  getUserNotification(limit = 0): Observable<any> {
    return this.http.get(`${environment.apiUrl}/account/notification?type=other&limit=${limit}&offset=0`);
  }

  getAllNotification(limit): Observable<any> {
    return this.http.get(`${environment.apiUrl}/account/notification?limit=${limit}&offset=0`);
  }
  getUserActivities(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/account/activity?type=other&limit=0&offset=0`);
  }

  getRequestReceived(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/account/notification?type=request_received&limit=0&offset=0`);
  }

  getRequestSent(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/account/activity?type=request_sent&limit=0&offset=0`);
  }

  setNotificationReadStatus(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/account/notification/set_notification_read_status/`);
  }

  getUnreadNotificationCount(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/account/notification/get_unread_notification_count/`);
  }

  bulkShareProfile(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/account/share/bulk_user_social_share`, data);
  }

  introduceConnections(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/account/introduce`, data);
  }

  addEducation(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/account/education`, data);
  }

  updateEducation(data, id): Observable<any> {
    return this.http.patch(`${environment.apiUrl}/account/education/${id}`, data);
  }

  deleteEducation(id): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/account/education/${id}`);
  }

  addExperience(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/account/experience`, data);
  }

  updateExperience(data, id): Observable<any> {
    return this.http.patch(`${environment.apiUrl}/account/experience/${id}`, data);
  }

  deleteExperience(id): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/account/experience/${id}`);
  }
}
