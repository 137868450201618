import { Component, OnInit } from '@angular/core';
import { AuthService } from '../authentication/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-login-by-admin',
  templateUrl: './login-by-admin.component.html',
  styleUrls: ['./login-by-admin.component.css']
})
export class LoginByAdminComponent implements OnInit {

  constructor(private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router) {
    localStorage.clear();
    this.route.queryParamMap.subscribe(queryParams => {
      const token = queryParams['params'].auth;
      localStorage.setItem('token', token);
      this.me();
    });
  }

  me() {
    this.authService.loginByAdmin().subscribe(res => {
      localStorage.setItem('userInfo', JSON.stringify(res.user));
      localStorage.setItem('firebaseToken', res.firebase_token);
      localStorage.setItem('security_token', res.security_token);
      localStorage.setItem('name', res.user.display_name);
      localStorage.setItem('email', res.user.email);
      localStorage.setItem('id', res.user.id);
      localStorage.setItem('profile', JSON.stringify(res.user.profile_image));
      localStorage.setItem('profile_status', '0');
      let userType = res.user.is_event_host ? 'organizer' : 'attendee';
      localStorage.setItem('userType', userType);

      if (userType === 'organizer') {
        this.router.navigate(['main/dashboard']);
      } else {
        this.router.navigate(['attendee/events']);
      }
    });
  }

  ngOnInit() {
  }

}
