export const Messages = {
  errors: {
    sessionExpired: 'Session expired! Please login in to continue',
    badRequest: 'Bad request. Please try again.',
    serverErr: 'Something went wrong. Please try again.',
    validationErr: 'Please fill all required fields.',
    notRegisterUser: 'You are not registered user.',
    failedToRegisterUser: 'Falied to register user.',
    otpNotMatched: 'OTP doesn\'t match. Please try again',
    invalidExtension: 'You can only upload jpg, png files!',
    invalidCsvExtension: 'You can only upload CSV file!',
    invalidData: 'Invalid data in file',
    selectEvent: 'Please select at least 1 event to perform this action!'
  },
  community: {
    inviteSend: 'Users invited to community successfully. Please allow up to 10 minutes for all invite emails to be sent out.',
    inviteDelete: 'Community invite deleted successfully.',
    memberDelete: 'Community Member deleted successfully.',
    reqAccepted: 'Community Member deleted successfully.',
    reqDeleted: 'Community Member deleted successfully.',
    sponsorCreate: 'Community Sponsor created successfully.'
  },
  campaigns: {
    created : 'Campaign created successfully.',
    notificationSent: 'Test notification sent successfully'
  },
  segments: {
    created : 'Segment created successfully.',
    deleted: 'Segment deleted successfully.',
    updated: 'Segment updated successfully.'
  },
  notifications: {
    updated: 'Transactional notification updated successfully.',
  }
};
