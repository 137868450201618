import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../shared.module';
import { HeaderComponent } from './header/header.component';
import { SearchComponent } from './search/search.component';
import { QuickViewComponent } from './quick-view/quick-view.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import { FooterComponent } from './footer/footer.component';
import { SideNavDirective } from '../directives/side-nav.directive';
import { ThemeConstantService } from '../services/theme-constant.service';
import { AttendeeSearchComponent } from './search/attendee-search/attendee-search.component';

@NgModule({
  exports: [
    HeaderComponent,
    SearchComponent,
    QuickViewComponent,
    SideNavComponent,
    SideNavDirective,
    FooterComponent,
    AttendeeSearchComponent
  ],
  imports: [
    RouterModule,
    SharedModule
  ],
  declarations: [
    HeaderComponent,
    SearchComponent,
    QuickViewComponent,
    SideNavComponent,
    SideNavDirective,
    FooterComponent,
    AttendeeSearchComponent
  ],
  providers: [
    ThemeConstantService
  ]
})

export class TemplateModule { }
