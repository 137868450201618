import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SiteAdminPortalGuard implements CanActivate {

  isProductHovr = environment.product === 'hovr' ? true : false;
  constructor(private router: Router) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.isProductHovr) {
      // this.router.navigateByUrl('/main/hovr/dashboard');
      this.router.navigateByUrl('/main/hovrs/list');
      return false;
    } else {
      return true;
    }
  }
}
